/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useRef, useState } from 'react'
import { number } from 'prop-types'
import { css } from '@emotion/css'
import IMask from 'imask'

import TextField from './TextField'

export const unFormat = (amount) =>
  IMask.pipe(
    amount,
    {
      mask: Number,
      scale: 2,
      thousandsSeparator: ' ',
      signed: false,
    },
    IMask.PIPE_TYPE.MASKED,
    IMask.PIPE_TYPE.TYPED,
  )

const Moula = ({ min, ...props }) => {
  const ref = useRef(null)
  const [value, setValue] = useState(-1)

  useEffect(() => {
    let mask
    if (ref.current) {
      const maskOptions = {
        mask: [
          { mask: '' },
          {
            mask: 'num €',
            lazy: false,
            blocks: {
              num: {
                mask: Number,
                scale: 2,
                thousandsSeparator: ' ',
                signed: false,
                min,
              },
            },
          },
        ],
      }
      mask = IMask(ref.current, maskOptions)
    }

    return () => {
      if (mask) mask.destroy()
    }
  }, [min])

  const handleKeyUp = (e) => {
    setValue(unFormat(e.target.value))
  }

  return (
    <div
      className={css`
        position: relative;
        width: 100%;

        & > input {
          position: absolute;
          top: 59px;
          height: 0px;
          opacity: 0;
        }
      `}
    >
      <TextField
        forwardRef={ref}
        error={value !== -1 && value < min ? `Minimum de ${min} €` : ''}
        {...props}
        onKeyUp={handleKeyUp}
        forceSelectedStyle
      />
      <input
        className={css`
          height: 0px;
          opacity: 0;
        `}
        type="number"
        min={min}
        value={value}
        readOnly
      />
    </div>
  )
}

Moula.defaultProps = {
  min: undefined,
}

Moula.propTypes = {
  min: number,
}

export default Moula
