/* eslint-disable react/jsx-props-no-spreading */
import { css, cx } from '@emotion/css'
import { string } from 'prop-types'

const H2 = ({ children, className, ...props }) => (
  <h2
    className={cx(
      css`
        color: #606060;
        font-size: 32px;

        @media (max-width: 450px) {
          font-size: 28px;
        }

        @media (max-width: 360px) {
          font-size: 26px;
        }
      `,
      className,
    )}
    {...props}
  >
    {children}
  </h2>
)

H2.defaultProps = {
  children: null,
  className: null,
}

H2.propTypes = {
  children: string,
  className: string,
}

export default H2
