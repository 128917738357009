import { css } from '@emotion/css'
import { string } from 'prop-types'
import H1 from '../../../UI/Headings/H1'

const Error = ({ msg }) => {
  return (
    <div
      className={css`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      `}
    >
      <H1>{msg}</H1>
    </div>
  )
}

Error.propTypes = {
  msg: string.isRequired,
}

export default Error
