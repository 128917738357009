/* eslint-disable react/jsx-props-no-spreading */
const { string } = require('prop-types')
const { cx, css } = require('@emotion/css')

const Button = ({ className, children, ...props }) => (
  <button
    type="button"
    className={cx(
      css`
        background-color: #606060;
        color: white;
        box-shadow: none;
        border: none;
        outline: none;
        padding: 5px 10px;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background-color: #5a5a5a;
        }

        &:active {
          background-color: #4c4c4c;
        }
      `,
    )}
    {...props}
  >
    {children}
  </button>
)

Button.defaultProps = {
  className: null,
  children: null,
}

Button.propTypes = {
  className: string,
  children: string,
}

export default Button
