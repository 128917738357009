/* eslint-disable react/jsx-props-no-spreading */
import { bool } from 'prop-types'

import TextField from './TextField'

const BigoPhone = ({ disabled, ...props }) => {
  return (
    <TextField
      type="tel"
      pattern="(^\+[0-9]{6,19}$|^[0-9]{6,20}$)"
      {...props}
    />
  )
}

BigoPhone.defaultProps = {
  disabled: false,
}

BigoPhone.propTypes = {
  disabled: bool,
}

export default BigoPhone
