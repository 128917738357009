import { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { node, bool, string } from 'prop-types'
import { css, cx } from '@emotion/css'

const Modal = ({ children, className, display }) => {
  const anchor = useRef(document.createElement('div'))

  useEffect(() => {
    const root = document.querySelector('#root')
    const { current } = anchor
    if (root && current) root.appendChild(anchor.current)

    return () => {
      if (current && root) root.removeChild(current)
    }
  }, [anchor])

  if (display === false) return null

  return createPortal(
    <div
      className={cx(
        css`
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #222222aa;

          display: flex;
          align-items: center;
          justify-content: center;

          & > div {
            background-color: #fff;
            border-radius: 6px;
          }
        `,
        className,
      )}
    >
      <div>{children}</div>
    </div>,
    anchor.current,
  )
}

Modal.defaultProps = {
  className: null,
}

Modal.propTypes = {
  children: node.isRequired,
  display: bool.isRequired,
  className: string,
}

export default Modal
