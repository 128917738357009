import { Link } from 'react-router-dom'
import { shape, string } from 'prop-types'
import { css } from '@emotion/css'

import Images from './Images'
import P from '../../../UI/P'

const Card = ({ campaign }) => (
  <Link
    to={`/${campaign?.customer?.sdis}/${campaign?.customer?.trigram}`}
    className={css`
      width: calc(100% - 10px - 10px);
      margin: 10px;

      @media (min-width: 951px) {
        width: calc(50% - 10px - 10px);
      }
    `}
  >
    <div
      className={css`
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow: hidden;
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
          0px 1px 1px 0px rgba(0, 0, 0, 0.14),
          0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        cursor: pointer;
        transition: box-shadow 0.2s ease;

        height: 270px;

        &:hover {
          box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.2),
            0px 1px 4px 0px rgba(0, 0, 0, 0.14),
            0px 1px 7px 0px rgba(0, 0, 0, 0.12);
        }

        & > .text-section {
          padding: 10px;

          p {
            margin: 0;
          }

          p:nth-of-type(1) {
            padding-bottom: 5px;
            color: #232222;
            font-size: 18px;
            font-weight: 500;

            @media (min-width: 951px) {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          p:nth-of-type(2) {
            font-size: 14px;
            font-weight: 400;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      `}
    >
      <div
        className={css`
          width: 100%;
          height: 200px;

          & > img {
            object-fit: contain;
            width: 100%;
            height: 100%;
          }

          & > div {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #dad8d8;
            color: #585858;
          }
        `}
      >
        <Images campaign={campaign} />
      </div>
      <div className="text-section">
        <P>{campaign?.customer?.legalName}</P>
        <P>{campaign?.description}</P>
      </div>
    </div>
  </Link>
)

Card.propTypes = {
  campaign: shape({
    name: string.isRequired,
  }).isRequired,
}

export default Card
