import { css } from '@emotion/css'

import WithMargins from '../Common/WithMargins'
import P from '../UI/P'

export const HEADER_HEIGHT = 70

const Header = () => (
  <div
    className={css`
      height: ${HEADER_HEIGHT}px;
      background-color: #ba1c18;
    `}
  >
    <WithMargins
      className={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;

        @media (max-width: 1170px) {
          margin-right: 10px;
        }

        @media (max-width: 600px) {
          font-size: 13px;

          p {
            margin-left: 10px;
          }

          img {
            height: 30px;
          }
        }

        & > p {
          color: #fff;
          text-align: end;

          a {
            color: #fff;
            font-weight: 700;
            text-decoration-color: #fff;
            margin-left: 10px;
          }

          a:hover {
            opacity: 0.8;
          }
        }
      `}
    >
      <img
        className={css`
          margin-top: 10px;
          margin-bottom: 10px;
          margin-left: 10px;
        `}
        height="50"
        src="https://static.dmasp.fr/images/dmasp-logo-white.svg"
        alt="DMASP"
        title=""
      />
      <P>
        Vous êtes une amicale ?
        <a href="https://www.dmasp.fr/#contact">Rendez-vous ici</a>
      </P>
    </WithMargins>
  </div>
)

export default Header
