export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'array',
  items: {
    type: 'object',
    properties: {
      id: {
        type: 'string',
      },
      customer: {
        type: 'object',
        properties: {
          legalName: {
            type: 'string',
          },
          trigram: {
            type: 'string',
          },
          sdis: {
            type: 'string',
          },
          postalCode: {
            type: 'integer',
          },
          city: {
            type: 'string',
          },
          photo: {
            type: ['string', 'null'],
          },
          photoUrl: {
            type: ['string', 'null'],
          },
        },
        required: ['legalName', 'trigram', 'sdis', 'postalCode', 'city'],
      },
      name: {
        type: 'string',
      },
      description: {
        type: ['string', 'null'],
      },
      minimumPrice: {
        type: 'integer',
      },
      pictures: {
        type: 'array',
        items: {
          type: 'object',
          properties: {
            imageUrl: {
              type: 'string',
            },
            id: {
              type: 'string',
            },
            position: {
              type: 'integer',
            },
          },
          required: ['imageUrl', 'id', 'position'],
        },
      },
    },
    required: [
      'id',
      'customer',
      'name',
      'description',
      'minimumPrice',
      'pictures',
    ],
  },
}
