import { useState } from 'react'
import { css, keyframes } from '@emotion/css'
import { arrayOf, number, shape, string } from 'prop-types'
import { useHistory } from 'react-router'
import Loader from 'react-feather/dist/icons/loader'
import { addBreadcrumb, captureException } from '@sentry/react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { GIFT_CREATE_PAYMENT_ERROR } from '../../../constants/stripe'
import TextField from '../../../UI/TextField'
import Modal from '../../../UI/Modal'
import Button from '../../../UI/Button'
import CheckBox from '../../../UI/CheckBox'
import Input from '../../../UI/Input'
import Moula, { unFormat } from '../../../UI/Moula'
import PostalCode from '../../../UI/PostalCode'
import P from '../../../UI/P'
import H3 from '../../../UI/Headings/H3'
import Card from '../../../UI/Card'
import BigoPhone from '../../../UI/BigoPhone'
import DataList from '../../../UI/DataList'
import Email from './Email'
import useSmartCity from './useSmartCity'
import { useStripeHelper } from '../../../Common/helpers/stripe'
import { sendConfirmationPayment, sendPayment } from './api'

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`

const getPrivateFields = (target, campaign) => {
  const {
    civility: { value: title },
    first_name: { value: firstName },
    last_name: { value: lastName },
    email: { value: email },
    floor_number: { value: floorNumber },
    apartment_number: { value: apartmentNumber },
    building: { value: building },
    address: { value: address },
    address_extra: { value: address2 },
    city: { value: city },
    postal_code: { value: postalCode },
    phone: { value: phoneNumber },
    personal_data_to_partner: { checked: acceptance },
    amount: { value: amount },
  } = target

  return {
    campaign: campaign?.id,
    title,
    firstName,
    lastName,
    email,
    floorNumber,
    apartmentNumber,
    building,
    address,
    address2,
    city,
    postalCode,
    phoneNumber,
    acceptance,
    amount: unFormat(amount),
  }
}

const getBusinessFields = (target, campaign) => {
  const {
    social_raison: { value: legalName },
    email: { value: email },
    floor_number: { value: floorNumber },
    apartment_number: { value: apartmentNumber },
    building: { value: building },
    address: { value: address },
    address_extra: { value: address2 },
    city: { value: city },
    postal_code: { value: postalCode },
    phone: { value: phoneNumber },
    personal_data_to_partner: { checked: acceptance },
    amount: { value: amount },
  } = target

  return {
    campaign: campaign?.id,
    legalName,
    email,
    floorNumber,
    apartmentNumber,
    building,
    address,
    address2,
    city,
    postalCode,
    phoneNumber,
    acceptance,
    amount: unFormat(amount),
  }
}

const WAITING = 'waiting'
const LOADING = 'loading'
const LOADED = 'success'
const ERROR = 'error'

const Form = ({ data }) => {
  const [mode, setMode] = useState('private')
  const [displayModal, setDisplayModal] = useState(false)
  const [acceptToShareData, setAcceptToShareData] = useState(false)
  const [postState, setPostState] = useState(WAITING)
  const [errorMsg, setErrorMsg] = useState('')
  const [successData, setSuccessData] = useState({ name: '', amount: '' })
  const [cities, handleCpChanges] = useSmartCity()

  const history = useHistory()

  const [{ createPaymentMethod, confirmCardPayment }] = useStripeHelper()

  const handleSubmit = async (e) => {
    try {
      setErrorMsg('')
      setPostState(LOADING)
      setDisplayModal(true)
      const {
        persona_type: { value: type },
      } = e.target

      const formFields =
        type === 'private'
          ? getPrivateFields(e.target, data?.campaign)
          : getBusinessFields(e.target, data?.campaign)
      const fields = {
        ...formFields,
        status: 'ONLINE',
        paymentMode: 'MODE_CARD',
      }

      if (fields.amount < data?.campaign?.minimumPrice) return

      const onSuccess = () => {
        setSuccessData({
          name:
            type === 'private'
              ? `${fields.title} ${fields.firstName} ${fields.lastName}`
              : fields.legalName,
          amount: fields.amount,
        })
      }

      const paymentMethod = await createPaymentMethod()

      const result = await sendPayment({
        ...fields,
        paymentMethodId: paymentMethod.id,
      })

      const actionsByStatusCode = {
        204: onSuccess,
        402: async () => {
          const paymentData = await result.json()
          const paymentIntent = confirmCardPayment(paymentData?.client_secret)
          await sendConfirmationPayment({
            ...fields,
            paymentIntentId: paymentIntent?.id,
          })

          onSuccess()
        },
      }

      if (actionsByStatusCode[result.status]) {
        await actionsByStatusCode[result.status]()
      } else {
        addBreadcrumb({
          message: `${GIFT_CREATE_PAYMENT_ERROR} : Unhandled result code`,
          level: 'error',
          data: { code: result.status, responseContent: await result.json() },
        })
        throw new Error('Erreur durant le processus de paiement')
      }

      setPostState(LOADED)
    } catch (error) {
      console.error(error)
      captureException(error)
      setErrorMsg(error?.message || 'Erreur durant le processus de paiement')
      setPostState(ERROR)
    }
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        handleSubmit(e)
      }}
      className={css`
        max-width: 600px;
        width: 100%;
        margin-bottom: 100px;

        & .checkbox,
        & .radio,
        & .textField {
          margin-bottom: 20px;
        }

        & .horizontale-align {
          display: flex;
          justify-content: center;
        }

        & .one-line {
          display: flex;

          .textField {
            flex: 1;
          }

          .textField:not(:last-child) {
            margin-right: 15px;
          }
        }

        .small {
          margin-right: 15px;
          width: 120px;
        }

        .medium {
          margin-right: 15px;
          width: 180px;
        }

        & .last-line {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          button {
            height: 66px;
            padding: 5px 20px;
          }
        }

        @media (max-width: 650px) {
          & .one-line {
            flex-direction: column;

            .textField:not(:last-child) {
              margin-right: 0;
            }
          }

          .small {
            width: 100%;
          }

          .medium {
            width: 100%;
          }

          .last-line {
            flex-direction: column;
            margin-left: 16px;
          }
        }

        & p.error-msg {
          transition: opacity 0.2s ease;
          color: #d81b60;
          margin: 10px 0;
        }

        h2 {
          border-bottom: 1px solid black;
          margin-bottom: 10px;
          padding-bottom: 10px;
        }
      `}
    >
      <h2>Information de paiement et livraison</h2>
      <div className="horizontale-align">
        <Input
          name="persona_type"
          id="form_persona_type"
          options={[
            { id: 'private', label: 'Particulier', value: 'private' },
            { id: 'business', label: 'Entreprise', value: 'business' },
          ]}
          onChange={setMode}
          disabled={postState === LOADING}
          tabMode
        />
      </div>
      {mode === 'private' && (
        <div className="one-line">
          <div className="small">
            <TextField
              name="civility"
              placeholder="Civilité"
              maxLength={10}
              disabled={postState === LOADING}
              required
            />
          </div>
          <TextField
            name="first_name"
            placeholder="Prénom"
            maxLength={50}
            disabled={postState === LOADING}
            required
          />
          <TextField
            name="last_name"
            placeholder="Nom"
            maxLength={50}
            disabled={postState === LOADING}
            required
          />
        </div>
      )}
      {mode === 'business' && (
        <TextField
          name="social_raison"
          placeholder="Raison sociale"
          maxLength={50}
          disabled={postState === LOADING}
          required
        />
      )}
      <Email
        name="email"
        maxLength={100}
        disabled={postState === LOADING}
        required
      />
      <BigoPhone
        name="phone"
        placeholder="Numéro de téléphone"
        maxLength={20}
        hint="Formats acceptés : 00 00 00 00 00 ou +330 00 00 00 00"
        disabled={postState === LOADING}
        required
      />
      <div className="one-line">
        <div className="medium">
          <TextField
            name="building"
            placeholder="N° Bâtiment"
            maxLength={30}
            disabled={postState === LOADING}
          />
        </div>
      </div>
      <div className="one-line">
        <TextField
          name="floor_number"
          placeholder="N° Etage / Voie"
          maxLength={10}
          disabled={postState === LOADING}
        />
        <TextField
          name="apartment_number"
          placeholder="N° Appartement"
          maxLength={10}
          disabled={postState === LOADING}
        />
      </div>
      <TextField
        name="address"
        placeholder="Adresse"
        maxLength={150}
        disabled={postState === LOADING}
        required
      />
      <TextField
        name="address_extra"
        placeholder="Complément d’adresse"
        maxLength={150}
        disabled={postState === LOADING}
      />
      <div className="one-line">
        <PostalCode
          name="postal_code"
          placeholder="Code postal"
          maxLength={5}
          disabled={postState === LOADING}
          onChange={handleCpChanges}
          required
        />
        <DataList
          id="city_id"
          name="city"
          label="Ville"
          maxLength={100}
          disabled={postState === LOADING}
          required
          list={{
            id: 'list_id',
            items: cities,
          }}
        />
      </div>
      <div className="medium">
        <TextField
          name="country"
          placeholder="Pays"
          value="France"
          disabled
          required
        />
      </div>
      <P style={{ opacity: acceptToShareData ? 0 : 1 }}>
        La première case doit être cochée pour pouvoir faire un don
      </P>
      <CheckBox
        id="personal_data"
        name="personal_data"
        onChange={() => setAcceptToShareData(!acceptToShareData)}
        // prettier-ignore
        label={(
          <>
            J’accepte de communiquer mes données personnelles à l’amicale et ses
            partenaires dans le but d’
            <strong>être livré de mon calendrier</strong>.
          </>
        )}
        checked={acceptToShareData}
        disabled={postState === LOADING}
        required
      />
      <CheckBox
        name="personal_data_to_partner"
        id="personal_data_to_partner"
        // prettier-ignore
        label={(
          <>
            J’accepte de communiquer mes données personnelles à l’amicale et ses
            partenaires dans le but de <strong>recevoir mon reçu par email</strong> et de
            recevoir des communications de leurs parts (Loto, Bal, Journée
            portes ouvertes...).
          </>
        )}
        disabled={postState === LOADING}
      />
      <Moula
        name="amount"
        placeholder="Montant du don"
        min={data?.campaign?.minimumPrice ?? 0}
        disabled={postState === LOADING}
        required
      />
      <Card disabled={postState === LOADING} />
      <div className="last-line">
        <Button type="submit" disabled={postState === LOADING}>
          Valider le don
        </Button>
      </div>
      <Modal
        className={css`
          & > div {
            max-width: 600px;
            width: 100%;
            box-sizing: border-box;

            div {
              display: flex;
              flex-direction: column;
              width: 100%;

              button {
                width: 100px;
                align-self: flex-end;
              }

              h3 {
                margin: 10px 0 5px;
                padding: 0 10px;
                padding-bottom: 4px;
                border-bottom: 1px solid #606060;
              }

              div.modal-content {
                padding: 0 10px 10px;
                box-sizing: border-box;
              }
            }

            div.loading {
              div.modal-content {
                display: flex;
                padding: 20px;
                p {
                  margin: 0 0 40px;
                }

                svg {
                  align-self: center;
                  animation: ${rotate} 2s ease-in-out infinite;
                }
              }
            }

            div.success button {
              width: 150px;
              align-self: flex-end;
            }
          }
        `}
        display={displayModal}
      >
        <Switch>
          {postState === LOADING && (
            <Route path="/:sdis([0-9]{2,3})/:trigramme([a-zA-Z]{3})/loading">
              <div className="loading">
                <H3>Création en cours</H3>
                <div className="modal-content">
                  <P>Enregistrement en cours, veuillez patienter.</P>
                  <Loader size={64} />
                </div>
              </div>
            </Route>
          )}
          {postState === ERROR && (
            <Route path="/:sdis([0-9]{2,3})/:trigramme([a-zA-Z]{3})/error">
              <div className="error">
                <H3>Erreur lors de l&apos;envoi</H3>
                <div className="modal-content">
                  <P>
                    {errorMsg ||
                      "Une erreur est survenue pendant l'envoi de vos données. Veuillez vérifier vos informations et réésayer."}
                  </P>
                  <P>
                    Si l&apos;erreur persiste vous pouvez nous contacter par
                    mail à l&apos;adresse suivante :{' '}
                    <a href="mailto:contact@dmasp.fr">contact@dmasp.fr</a>
                  </P>
                  <Button onClick={() => setDisplayModal(false)}>Fermer</Button>
                </div>
              </div>
            </Route>
          )}
          {postState === LOADED && (
            <Route path="/:sdis([0-9]{2,3})/:trigramme([a-zA-Z]{3})/success">
              <div className="success">
                <H3>Merci pour votre don</H3>
                <div className="modal-content">
                  <P className="error-msg">
                    {successData.name} nous avons bien reçu votre don d&apos;un
                    montant de {successData.amount}€.
                  </P>
                  <Button onClick={() => history.push('/')}>
                    Retourner à la liste
                  </Button>
                </div>
              </div>
            </Route>
          )}
          <Route path="/:sdis([0-9]{2,3})/:trigramme([a-zA-Z]{3})/waiting">
            <div />
          </Route>
          <Redirect
            to={`/${data?.customer?.sdis}/${data?.customer?.trigram}/${postState}`}
          />
        </Switch>
      </Modal>
    </form>
  )
}

Form.propTypes = {
  data: shape({
    campaign: shape({
      id: string.isRequired,
      name: string.isRequired,
      description: string,
      minimumPrice: number.isRequired,
      pictures: arrayOf(
        shape({
          id: string.isRequired,
          imageUrl: string.isRequired,
          position: number.isRequired,
        }).isRequired,
      ).isRequired,
    }),
    customer: shape({
      legalName: string.isRequired,
      trigram: string.isRequired,
      sdis: string.isRequired,
      postalCode: number.isRequired,
      city: string.isRequired,
      photo: string,
      photoUrl: string,
    }).isRequired,
  }).isRequired,
}

export default Form
