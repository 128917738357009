/* eslint-disable react/jsx-props-no-spreading */
import { arrayOf, shape, string } from 'prop-types'
import TextField from './TextField'

const DataList = ({ id, name, label, list, ...props }) => {
  return (
    <>
      <datalist id={list.id}>
        {list.items.map((item) => (
          <option key={item.id} value={item.title}>
            {item.subTitle}
          </option>
        ))}
      </datalist>
      <TextField
        name={name}
        list={list.id}
        id={id}
        placeholder={label}
        {...props}
      />
    </>
  )
}

DataList.propTypes = {
  id: string.isRequired,
  name: string.isRequired,
  label: string.isRequired,
  list: shape({
    id: string.isRequired,
    items: arrayOf(
      shape({
        id: string.isRequired,
        title: string.isRequired,
        subTitle: string,
      }).isRequired,
    ).isRequired,
  }).isRequired,
}

export default DataList
