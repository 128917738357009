import { useState } from 'react'
import { groupWith, filter, compose } from 'ramda'
import { css } from '@emotion/css'
import { remove as removeDiacritics } from 'diacritics'

import SearchIcon from 'react-feather/dist/icons/search'

import Fetch, { ERROR, LOADED } from '../../../Common/Fetch'

import H1 from '../../../UI/Headings/H1'
import P from '../../../UI/P'
import TextField from '../../../UI/TextField'
import Button from '../../../UI/Button'
import getDepCode from './getDepCode'
import Card from './Card'
import schema from './schema'
import WithMargins from '../../../Common/WithMargins'

const sanitize = (value = '') => removeDiacritics((value ?? '').toLowerCase())

const compare = (search) => (toValue) =>
  toValue.some((toCompare) => sanitize(toCompare).match(sanitize(search)))

const Search = () => {
  const [search, setSearch] = useState('')
  return (
    <WithMargins>
      <div
        className={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          overflow-y: auto;
          padding: 5px 0;

          & > p {
            text-align: center;
            font-size: 20px;
            max-width: 850px;
          }

          @media (min-width: 450px) {
            & input {
              width: 360px;
            }

            & > p {
              font-size: 16px;
            }
          }
        `}
      >
        <H1
          className={css`
            text-align: center;
          `}
        >
          Calendriers des Pompiers de votre commune
        </H1>
        <P
          className={css`
            font-weight: 800;
          `}
        >
          Pour commander votre calendrier 2021, c&apos;est simple : vous faites
          votre participation financière sur ce site et en retour vous recevez
          votre calendrier !
        </P>
        <P
          className={css`
            font-weight: 500;
          `}
        >
          Quels sens ont vos étrennes lorsque vous achetez un calendrier aux
          Pompiers de votre commune ?
        </P>
        <P
          className={css`
            font-weight: 300;
            margin-top: 0;
          `}
        >
          Votre don financera le fonctionnement de l&apos;Œuvre des pupilles
          orphelins et fonds d&apos;entraide des sapeurs-pompiers et les actions
          sociales et solidaires en soutien aux Pompiers et à leurs familles.
        </P>
        <TextField
          icon={<SearchIcon />}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Rechercher"
        />
        <Fetch
          url={`${process.env.REACT_APP_API_ENDPOINT}/api/online-sales/`}
          schema={schema}
        >
          {({ status, result, refetch }) => {
            if (status === ERROR) {
              return (
                <div
                  className={css`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 20px;

                    & button {
                      width: 120px;
                    }
                  `}
                >
                  <P>Erreur de chargement</P>
                  <Button onClick={refetch}>Réésayer</Button>
                </div>
              )
            }
            if (status === LOADED) {
              const data = Array.isArray(result?.data?.data)
                ? result?.data?.data
                : result?.data

              const groupTitle = (asso) => `Département ${getDepCode(asso)}`

              const searchFilter = (d) =>
                compare(search)([
                  d?.name,
                  d?.description,
                  d?.customer?.legalName,
                  d?.customer?.trigram,
                  d?.customer?.sdis,
                  `${d?.customer?.postalCode || ''}`,
                  d?.customer?.city,
                  groupTitle(d),
                ])

              const byGroup = compose(
                groupWith((a, b) => getDepCode(a) === getDepCode(b)),
                filter(searchFilter),
              )(data ?? [])

              return (
                <>
                  {byGroup.length === 0 && (
                    <div className="empty">
                      <P
                        className={css`
                          text-align: center;
                          margin: 30px 0;
                        `}
                      >
                        {search.length === 0
                          ? 'Actuellement aucune amicale de Sapeurs-Pompiers ou de Marins-Pompiers ne propose de calendrier en ligne.'
                          : 'Désolé mais aucune amicale de Sapeurs-Pompiers ou de Marins-Pompiers ne correspond à votre recherche.'}
                      </P>
                    </div>
                  )}
                  {byGroup.map((group) => (
                    <div
                      className={css`
                        width: 100%;
                        margin-bottom: 15px;

                        & h2 {
                          color: #606060;
                          font-size: 32px;
                          text-align: center;
                        }

                        @media (max-width: 450px) {
                          & h2 {
                            font-size: 28px;
                          }
                        }
                      `}
                      key={group[0].id}
                    >
                      <h2>{groupTitle(group[0])}</h2>
                      <div
                        className={css`
                          margin-top: 20px;
                          width: 100%;
                          display: flex;
                          flex-wrap: wrap;
                        `}
                      >
                        {group?.map((campaign) => (
                          <Card key={campaign?.id} campaign={campaign} />
                        ))}
                      </div>
                    </div>
                  ))}
                </>
              )
            }

            return null
          }}
        </Fetch>
      </div>
    </WithMargins>
  )
}

export default Search
