/* eslint-disable react/jsx-props-no-spreading */
import { css, cx } from '@emotion/css'
import { string } from 'prop-types'

const H4 = ({ children, className, ...props }) => (
  <h4
    className={cx(
      css`
        color: #606060;
        font-size: 18px;
      `,
      className,
    )}
    {...props}
  >
    {children}
  </h4>
)

H4.defaultProps = {
  children: null,
  className: null,
}

H4.propTypes = {
  children: string,
  className: string,
}

export default H4
