import { css } from '@emotion/css'
import { number } from 'prop-types'
import { fromNow } from '../../../Common/helpers/date'
import useUpdateComponentEvery from './useUpdateComponentEvery'

/** @param {{ time: number }} props */
const Expire = ({ time }) => {
  useUpdateComponentEvery(10_000)

  return (
    <p
      className={css`
        margin: 0;
        font-size: 14px;
        text-align: center;
        color: hsl(0, 0%, 31.38%);
      `}
    >
      Le lien de paiement expire dans <b>{fromNow(time)}</b>
    </p>
  )
}

Expire.propTypes = {
  time: number.isRequired,
}

export default Expire
