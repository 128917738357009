import { Route, Switch } from 'react-router'
import CB from './CB'
import Success from './Success'

const Payment = () => {
  return (
    <Switch>
      <Route exac path="/payment/:token/success">
        <Success />
      </Route>
      <Route exac path="/payment/:token">
        <CB />
      </Route>
    </Switch>
  )
}

export default Payment
