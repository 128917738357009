/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useRef } from 'react'
import IMask from 'imask'

import TextField from './TextField'

const PostalCode = (props) => {
  const ref = useRef(null)

  useEffect(() => {
    let mask
    if (ref.current) {
      const maskOptions = {
        mask: [
          {
            mask: '00000',
          },
        ],
      }
      mask = IMask(ref.current, maskOptions)
    }

    return () => {
      if (mask) mask.destroy()
    }
  }, [])

  return <TextField forwardRef={ref} pattern="[0-9]{5}" {...props} />
}

PostalCode.propTypes = {}

export default PostalCode
