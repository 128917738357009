import WithMargins from '../../../Common/WithMargins'

const Success = () => {
  return (
    <WithMargins>
      <p>Merci pour votre don, vous pouvez fermer la fenêtre</p>
    </WithMargins>
  )
}

export default Success
