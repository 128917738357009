export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  properties: {
    campaign: {
      anyOf: [
        { type: 'null' },
        {
          type: 'object',
          properties: {
            id: {
              type: 'string',
            },
            name: {
              type: 'string',
            },
            description: {
              type: ['string', 'null'],
            },
            minimumPrice: {
              type: 'integer',
            },
            pictures: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  imageUrl: {
                    type: 'string',
                  },
                  id: {
                    type: 'string',
                  },
                  position: {
                    type: 'integer',
                  },
                },
                required: ['imageUrl', 'id', 'position'],
              },
            },
          },
          required: ['id', 'name', 'description', 'minimumPrice', 'pictures'],
        },
      ],
    },
    customer: {
      type: 'object',
      properties: {
        legalName: {
          type: 'string',
        },
        trigram: {
          type: 'string',
        },
        sdis: {
          type: 'string',
        },
        postalCode: {
          type: 'integer',
        },
        city: {
          type: 'string',
        },
      },
      required: ['legalName', 'trigram', 'sdis', 'postalCode', 'city'],
    },
  },
  required: ['campaign', 'customer'],
}
