import { useEffect, useState } from 'react'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
} from '@stripe/react-stripe-js'
import { css } from '@emotion/css'
import { captureException } from '@sentry/react'
import { bool } from 'prop-types'

import H3 from './Headings/H3'
import H4 from './Headings/H4'

const options = {
  style: {
    base: {
      color: '#606060',
      fontSize: '20px',
    },
  },
}

/**
 * @param {{ disabled: boolean }} props
 */
const Card = ({ disabled }) => {
  const elements = useElements()
  const [ready, setReady] = useState({
    number: false,
    expiry: false,
    cvc: false,
  })
  const allReady = ready.number && ready.expiry && ready.cvc

  useEffect(() => {
    if (elements) {
      const number = elements.getElement(CardNumberElement)
      const expiry = elements.getElement(CardExpiryElement)
      const cvc = elements.getElement(CardCvcElement)
      number.update({ disabled })
      expiry.update({ disabled })
      cvc.update({ disabled })
    }
  }, [disabled, elements])

  return (
    <div
      className={css`
        flex: 1;
        display: flex;
        flex-direction: column;
        border-radius: 9px;
        box-sizing: border-box;
        border: 1px solid #606060;
        margin-bottom: 20px;
        padding: 15px 20px 40px;

        & > .StripeElement {
          width: 100%;
        }

        & > h3 {
          margin: 5px 0 0;
        }

        & > h4 {
          margin: 10px 0 25px;
        }

        & > input {
          height: 1;
          width: 100%;
          opacity: 0;
        }

        & > div.infos {
          & > div {
            margin-top: 20px;
            display: flex;
            /* justify-content: space-between; */

            & > div:first-child {
              width: calc(100% - 50px);
            }
            & > div:nth-child(2) {
              width: 50px;
            }
          }
        }
      `}
    >
      <H3>Paiement par carte bancaire</H3>
      <H4>Merci de renseigner vos informations de carte bancaire</H4>
      <div className="infos">
        <CardNumberElement
          options={options}
          onChange={(e) => {
            if (e.error)
              captureException(e.error, { contexts: { stripe: true } })
            setReady({ ...ready, number: e.complete })
          }}
        />
        <div>
          <div>
            <CardExpiryElement
              options={options}
              onChange={(e) => {
                if (e.error)
                  captureException(e.error, { contexts: { stripe: true } })
                setReady({ ...ready, number: e.complete })
              }}
            />
          </div>
          <div>
            <CardCvcElement
              options={options}
              onChange={(e) => {
                if (e.error)
                  captureException(e.error, { contexts: { stripe: true } })
                setReady({ ...ready, number: e.complete })
              }}
            />
          </div>
        </div>
      </div>
      <input value={allReady ? 'ok' : ''} readOnly required />
    </div>
  )
}

Card.defaultProps = {
  disabled: false,
}

Card.propTypes = {
  disabled: bool,
}

export default Card
