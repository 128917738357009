/* eslint-disable import/no-unresolved */
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import fr from 'dayjs/locale/fr'

dayjs.extend(relativeTime)
dayjs.locale(fr)

/** @param {dayjs.ConfigType} time */
// eslint-disable-next-line import/prefer-default-export
export const fromNow = (time) => {
  return dayjs(time).fromNow(true)
}
