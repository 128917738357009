import { Component } from 'react'
import { node } from 'prop-types'
import { captureException } from '@sentry/react'
import { css } from '@emotion/css'

import H1 from '../Headings/H1'
import H2 from '../Headings/H2'
import P from '../P'

class Catch extends Component {
  state = {
    error: false,
  }

  static getDerivedStateFromError() {
    return { error: true }
  }

  componentDidCatch(error) {
    captureException(error)
  }

  render() {
    const { error } = this.state
    if (error) {
      return (
        <div
          className={css`
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          `}
        >
          <H1>Une erreur est survenue</H1>
          <H2>Veuillez recharger la page</H2>
          <P>
            Si l&apos;erreur persiste veuillez nous contacter :{' '}
            <a href="mailto:contact@dmasp.fr">contact@dmasp.fr</a>
          </P>
        </div>
      )
    }
    const { children } = this.props

    return children
  }
}

Catch.propTypes = {
  children: node.isRequired,
}

export default Catch
