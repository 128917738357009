/* eslint-disable react/jsx-props-no-spreading */
import { css, cx } from '@emotion/css'
import { node, string, bool, func, oneOfType, number, shape } from 'prop-types'
import { useRef, useState } from 'react'

import P from './P'

const TextField = ({
  icon,
  className,
  placeholder,
  required,
  disabled,
  hideLabel,
  value,
  defaultValue,
  onChange,
  id,
  forwardRef,
  forceSelectedStyle,
  hint,
  error,
  ...props
}) => {
  const [focused, setFocused] = useState(false)
  const [invalid, setInvalid] = useState(false)
  const inputRef = useRef(null)
  const [containValue, setContainValue] = useState(
    Boolean(value ?? defaultValue),
  )

  function handleChange(e) {
    setContainValue(Boolean(e.target.value))
    setInvalid(false)
    // eslint-disable-next-line no-unused-expressions
    onChange?.(e)
  }

  const label = `${placeholder}${required ? ' *' : ''}`
  const message = hint || error

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      onClick={() => {
        ;(inputRef?.current || forwardRef?.current)?.focus()
      }}
      className={cx(
        css`
          position: relative;
          display: flex;
          align-items: center;
          border: 1px solid #606060;
          color: #606060;
          padding: 18px 15px;
          border-radius: 4px;
          transition: border 0.2s ease;

          & > div {
            display: flex;
            width: 100%;
            flex-direction: column;
            justify-content: center;
          }

          & input {
            width: 100%;
            font-size: 20px;
            margin-top: 3px;
            border: none;
            outline: none;
            background-color: white;
          }

          & svg {
            margin-right: 10px;
          }

          &:hover:not(.disabled) {
            border: 1px solid #90a4ae;
            cursor: text;
          }

          &.focused {
            color: #5a5a5a;
            border: 1px solid #78909c;
          }

          &.invalid {
            border: 1px solid #bd1b1b;
          }

          label {
            position: absolute;
            top: 18px;
            font-size: 18px;
            opacity: 0;
            cursor: text;
            transition: transform 0.2s ease, font-size 0.2s ease;
          }

          &.forceSelectedStyle,
          &:focus-within,
          &.containValue {
            label {
              transform: translateY(-27px);
              font-size: 13px;
              background-color: white;
              padding: 0 5px;
              margin-left: -5px;
            }
          }

          &:focus-within {
            div.message {
              opacity: 0.9;
            }
          }

          &.displayLabel {
            label {
              opacity: 1;
            }
          }

          & div.message {
            position: absolute;
            bottom: 2px;
            opacity: 0;
            transition: opacity 0000.2s ease;

            p {
              margin: 0;
              font-size: 11px;
            }
          }

          &.errorMsg div.message {
            opacity: 1;

            p {
              color: #d81b60;
            }
          }
        `,
        {
          focused,
          textField: true,
          disabled,
          containValue,
          displayLabel: !hideLabel,
          forceSelectedStyle,
          invalid,
          errorMsg: !!error,
        },
        className,
      )}
    >
      {icon}
      <div>
        <label htmlFor={id}>{label}</label>
        <input
          ref={forwardRef || inputRef}
          id={id}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onInvalid={() => setInvalid(true)}
          placeholder={hideLabel ? label : ''}
          required={required}
          disabled={disabled}
          defaultValue={defaultValue}
          value={value}
          onChange={handleChange}
          {...props}
        />
        <div className="message">{message && <P>{message}</P>}</div>
      </div>
    </div>
  )
}

TextField.defaultProps = {
  className: null,
  icon: null,
  placeholder: null,
  required: false,
  disabled: false,
  hideLabel: false,
  forceSelectedStyle: false,
  value: undefined,
  defaultValue: undefined,
  onChange: null,
  id: null,
  forwardRef: null,
  hint: null,
  error: null,
}

TextField.propTypes = {
  className: string,
  hideLabel: bool,
  forceSelectedStyle: bool,
  icon: node,
  placeholder: string,
  required: bool,
  disabled: bool,
  value: oneOfType([string, number]),
  defaultValue: oneOfType([string, number]),
  onChange: func,
  id: string,
  hint: string,
  error: string,
  forwardRef: shape({ current: shape({}) }),
}

export default TextField
