import { Switch, Route, Redirect } from 'react-router-dom'
import { injectGlobal } from '@emotion/css'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import Search from './Pages/Search'
import Amicale from './Pages/Amicale'
import Header from './Header'
import Footer from './Footer'

import Layout from '../UI/Layout'
import Payment from './Pages/Payment'

// eslint-disable-next-line no-unused-expressions
injectGlobal`
  body {
    font-family: 'Lato';
  }

  a {
    text-decoration: none;
  }

  // Remove input number cursor
  .no-spin {
    input[type='number']::-webkit-inner-spin-button {
      display: none;
    }
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
`

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

const App = () => (
  <div>
    <Layout header={<Header />} footer={<Footer />}>
      <Switch>
        <Route exact path="/">
          <Search />
        </Route>
        <Route exact path="/:sdis([0-9]{2,3})/:trigramme([a-zA-Z]{3})/:status?">
          <Elements stripe={stripePromise}>
            <Amicale />
          </Elements>
        </Route>
        <Route exact path="/payment/:token*">
          <Elements stripe={stripePromise}>
            <Payment />
          </Elements>
        </Route>
        <Redirect to="/" />
      </Switch>
    </Layout>
  </div>
)

export default App
