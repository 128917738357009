/* eslint-disable react/jsx-props-no-spreading */
import { css, cx } from '@emotion/css'
import { node, string } from 'prop-types'

const WithMargins = ({ children, className, ...props }) => (
  <div
    className={cx(
      css`
        margin: auto;
        max-width: 1170px;
      `,
      className,
    )}
    {...props}
  >
    {children}
  </div>
)

WithMargins.defaultProps = {
  children: null,
  className: null,
}

WithMargins.propTypes = {
  children: node,
  className: string,
}

export default WithMargins
