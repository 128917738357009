const { useState, useEffect, useCallback } = require('react')

const serviceUrl = 'https://geo.api.gouv.fr/communes'

const useSmartCity = () => {
  const [cpValue, setCpValue] = useState('')
  const [cities, setCities] = useState([])

  const featchCities = useCallback(async () => {
    try {
      const url = `${serviceUrl}?codePostal=${cpValue}&fields=nom,code&format=json&geometry=centre`
      const result = await fetch(url)

      if (result.status === 200) {
        const json = await result.json()
        setCities(
          json.map(({ nom, code }) => ({ id: `${code}_${nom}`, title: nom })),
        )
      }
    } catch (error) {
      console.error(error)
    }
  }, [setCities, cpValue])

  useEffect(() => {
    if (cpValue.length === 5) {
      featchCities()
    }
  }, [featchCities, cpValue])

  const handleCpChanges = (e) => {
    setCpValue(e.target.value)
  }

  return [cities, handleCpChanges]
}

export default useSmartCity
