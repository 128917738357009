import { css } from '@emotion/css'
import { node } from 'prop-types'
import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import { HEADER_HEIGHT } from '../../App/Header'

import Catch from './Catch'

const Layout = ({ header, footer, children }) => {
  const layoutRef = useRef(null)
  const location = useLocation()

  useEffect(() => {
    if (layoutRef.current) layoutRef.current.scroll(0, 0)
  }, [location.pathname, layoutRef])

  return (
    <div
      className={css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;

        .layout-content {
          height: calc(100vh - ${HEADER_HEIGHT}px);
          overflow-y: auto;
        }

        .layout-children {
          min-height: calc(100vh - ${HEADER_HEIGHT}px);
        }
      `}
    >
      {header}
      <div ref={layoutRef} className="layout-content">
        <div className="layout-children">
          <Catch>{children}</Catch>
        </div>
        {footer}
      </div>
    </div>
  )
}

Layout.propTypes = {
  header: node.isRequired,
  footer: node.isRequired,
  children: node.isRequired,
}

export default Layout
