import { useEffect, useRef, useState } from 'react'

/** @param {number} ms */
const useUpdateComponentEvery = (ms) => {
  const [update, setUpdate] = useState(1)
  const updateRef = useRef(update)
  useEffect(() => {
    updateRef.current = update
  }, [update])

  useEffect(() => {
    const interval = setInterval(() => {
      setUpdate(updateRef.current + 1)
    }, ms)

    return () => clearInterval(interval)
  }, [ms])
}

export default useUpdateComponentEvery
