import { useLocation, useParams } from 'react-router-dom'
import { css } from '@emotion/css'

import Fetch, { ERROR, LOADED } from '../../../Common/Fetch'
import schema from './schema'

import Success from './Success'
import Error from './Error'
import WithMargins from '../../../Common/WithMargins'
import BreadCrumb from '../../../UI/BreadCrumb'

const Amicale = () => {
  const { trigramme } = useParams()
  const location = useLocation()

  return (
    <div
      className={css`
        & a {
          color: #606060;
          transition: opacity 0.2s ease;
        }

        & a:hover {
          opacity: 0.7;
        }

        & div.breadcrumb {
          margin-left: 20px;
        }
      `}
    >
      <Fetch
        url={`${process.env.REACT_APP_API_ENDPOINT}/api/online-sales/trigram/${trigramme}`}
        schema={schema}
      >
        {({ status, result }) => {
          const breadCrumb = (
            <WithMargins>
              <BreadCrumb
                paths={[
                  { label: 'Liste des amicales', url: '/' },
                  {
                    label: result?.data?.customer?.legalName ?? trigramme,
                    url: location.pathname,
                  },
                ]}
              />
            </WithMargins>
          )

          if (result?.status === 404) {
            return (
              <>
                {breadCrumb}
                <Error msg="Cette amicale ne propose pas de don en ligne" />
              </>
            )
          }

          if (status === ERROR) {
            return (
              <>
                {breadCrumb}
                <Error msg="Une erreur est survenue veuillez recharger la page" />
              </>
            )
          }

          if (status === LOADED && !result?.data?.campaign) {
            return (
              <>
                {breadCrumb}
                <Error msg="La vente de calendrier pour cette amicale est terminée" />
              </>
            )
          }

          if (status === LOADED) {
            return (
              <WithMargins>
                {breadCrumb}
                <Success result={result} />
              </WithMargins>
            )
          }

          return <WithMargins>{breadCrumb}</WithMargins>
        }}
      </Fetch>
    </div>
  )
}

export default Amicale
