/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { css, cx } from '@emotion/css'
import { useState } from 'react'
import { arrayOf, shape, string } from 'prop-types'

import Image from 'react-feather/dist/icons/image'

const Images = ({ images }) => {
  const [selected, setSelected] = useState(0)

  if (images.length === 0) {
    return (
      <div
        className={cx(
          css`
            width: 500px;
            height: 500px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #dad8d8;
            color: #585858;
          `,
          { images: true, empty: true },
        )}
      >
        <Image size={80} />
      </div>
    )
  }

  return (
    <div
      className={cx(
        css`
          position: relative;
          width: 500px;
          height: 500px;
          display: flex;
          align-items: center;
          justify-content: center;

          & > img {
            object-fit: contain;
            width: 100%;
            height: 100%;
          }

          & > div {
            position: absolute;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            bottom: 10px;
            left: 10px;
            right: 10px;
            height: 100px;
            overflow-x: auto;
            overflow-y: hidden;

            img {
              height: 100px;
              width: 100px;
              border: 2px solid #fff;
              transition: border 0.2s ease;
              cursor: pointer;
              margin-right: 10px;
            }

            img:hover {
              border: 2px solid #125aad;
            }

            img.selected {
              border: 2px solid #0e4481;
            }
          }
        `,
        'images',
      )}
    >
      <img src={images[selected]?.src} alt="Illustration de la caserne" />
      <div className="image_selector">
        {images.length > 1 &&
          images.map((image, i) => (
            <img
              className={cx({ selected: i === selected })}
              src={image.src}
              key={image.src}
              onClick={() => setSelected(i)}
              onKeyPress={() => setSelected(i)}
              alt="Illustration de la caserne"
            />
          ))}
      </div>
    </div>
  )
}

Images.propTypes = {
  images: arrayOf(
    shape({
      src: string.isRequired,
    }),
  ).isRequired,
}

export default Images
