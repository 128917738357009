import { addBreadcrumb } from '@sentry/react'
import { GIFT_CONFIRMATION_ERROR } from '../../../constants/stripe'

export const sendPayment = async (data) => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/api/online-sales/create-payment`
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
  })
}

export const sendConfirmationPayment = async (body) => {
  const confirmationResult = await sendPayment(body)

  if (confirmationResult.status !== 204) {
    addBreadcrumb({
      message: GIFT_CONFIRMATION_ERROR,
      level: 'error',
      data: { responseContent: await confirmationResult.json() },
    })

    throw new Error('Erreur pendant la procédure de confirmation.')
  }

  return confirmationResult
}
