import { css } from '@emotion/css'
import { addBreadcrumb, captureException } from '@sentry/react'
import { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router'

import { CB_CREATE_PAYMENT_ERROR } from '../../../constants/stripe'
import WithMargins from '../../../Common/WithMargins'
import Button from '../../../UI/Button'
import Card from '../../../UI/Card'
import { useStripeHelper } from '../../../Common/helpers/stripe'
import {
  ExpiredTokenError,
  fetchVisitStatus,
  InvalidTokenError,
  sendConfirmationPayment,
  sendPayment,
} from './api'
import Expire from './Expire'

const CB = () => {
  const history = useHistory()
  const location = useLocation()
  const [visit, setVisit] = useState(undefined)
  const [visitError, setVisitError] = useState(undefined)

  const [{ createPaymentMethod, confirmCardPayment }] = useStripeHelper()

  const token = useMemo(() => location.pathname.split('/')[2], [location])
  const data = useMemo(() => {
    try {
      const b64 = token.split('.')[1]
      return JSON.parse(atob(b64))
    } catch (error) {
      console.error(error)
      return null
    }
  }, [token])

  const [errorMsg, setErrorMsg] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const checkVisitStatus = async () => {
      try {
        if (token) {
          const result = await fetchVisitStatus(token)
          setVisit(await result.json())
        }
      } catch (error) {
        console.error(error)

        setVisit(null)
        setVisitError(error)

        if (
          !(error instanceof ExpiredTokenError) &&
          !(error instanceof InvalidTokenError)
        ) {
          captureException(error, { contexts: { token } })
        }
      }
    }

    checkVisitStatus()
  }, [token])

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      if (loading) return
      if (!token) throw new Error('Missing token')
      setErrorMsg(null)
      setLoading(true)

      const paymentMethod = await createPaymentMethod()

      const result = await sendPayment(token, {
        paymentMethodId: paymentMethod?.id,
      })

      const actionsByStatusCode = {
        204: () => {
          history.replace(`/payment/${token}/success`)
        },
        402: async () => {
          const paymentData = await result.json()
          const paymentIntent = await confirmCardPayment(
            paymentData?.client_secret,
          )
          await sendConfirmationPayment(token, {
            paymentIntentId: paymentIntent?.id,
          })

          history.replace(`/payment/${token}/success`)
        },
      }

      if (actionsByStatusCode[result.status]) {
        await actionsByStatusCode[result.status]()
      } else {
        addBreadcrumb({
          message: `${CB_CREATE_PAYMENT_ERROR} : Unhandled result code`,
          level: 'info',
          data: { code: result.status, responseContent: await result.json() },
        })
        throw new Error('Erreur durant le processus de paiement')
      }
    } catch (error) {
      console.error(error)
      captureException(error)
      setErrorMsg(error?.message || 'Erreur durant le processus de paiement')
    }

    setLoading(false)
  }

  if (visit === undefined) {
    return (
      <WithMargins>
        <p>Chargement en cours</p>
      </WithMargins>
    )
  }

  if (visit === null) {
    if (visitError instanceof ExpiredTokenError) {
      // il nous faudrait une solution pour le donateur dans ce cas !
      return (
        <WithMargins>
          <p>Le lien de paiement à expiré</p>
        </WithMargins>
      )
    }

    if (visitError instanceof InvalidTokenError) {
      return (
        <WithMargins>
          <p>Le lien de paiement est invalide</p>
        </WithMargins>
      )
    }

    return (
      <WithMargins>
        <p>Erreur durant le processsus, veuillez réessayer plus tard</p>
      </WithMargins>
    )
  }

  if (visit.status === 'DON') {
    return (
      <WithMargins>
        <p>Votre don a bien été reçu, merci de votre participation</p>
      </WithMargins>
    )
  }

  return (
    <WithMargins
      className={css`
        display: flex;
        justify-content: center;
      `}
    >
      <form
        className={css`
          display: flex;
          flex-direction: column;
          max-width: 600px;
          width: calc(100% - 20px);
          margin: 10px 10px 100px;

          & > button {
            align-self: flex-end;
          }
        `}
        onSubmit={handleSubmit}
      >
        <Expire time={data.exp * 1000} />
        <h1>Bienvenue sur notre plateforme de paiement en ligne</h1>
        <p>
          Veuillez confirmer et payer votre don de {data?.amountCollectedCard}€
        </p>
        <Card disabled={loading} />
        <Button type="submit" disabled={loading}>
          {loading ? 'Traitement en cours' : 'Valider le don'}
        </Button>
        <p className="error">{errorMsg}</p>
      </form>
    </WithMargins>
  )
}

export default CB
