/* eslint-disable react/jsx-props-no-spreading */
import { css, cx } from '@emotion/css'
import { node, oneOfType, string } from 'prop-types'

const CheckBox = ({ id, label, ...props }) => (
  <div
    className={cx(
      css`
        display: flex;
        align-items: center;

        & label {
          margin-left: 5px;
          color: #606060;
        }

        & label strong {
          font-weight: 700;
          color: #161616;
        }
      `,
      { checkbox: true },
    )}
  >
    <input type="checkbox" id={id} {...props} />
    <label htmlFor={id}>{label}</label>
  </div>
)

CheckBox.propTypes = {
  id: string.isRequired,
  label: oneOfType([string, node]).isRequired,
}

export default CheckBox
