/* eslint-disable react/jsx-props-no-spreading */
import { css, cx } from '@emotion/css'
import { array, oneOfType, string } from 'prop-types'

const P = ({ children, className, ...props }) => (
  <p
    className={cx(
      css`
        color: #606060;
      `,
      className,
    )}
    {...props}
  >
    {children}
  </p>
)

P.defaultProps = {
  children: null,
  className: null,
}

P.propTypes = {
  children: oneOfType([string, array]),
  className: string,
}

export default P
