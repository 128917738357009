import { shape, string } from 'prop-types'
import ImageIcon from 'react-feather/dist/icons/image'

const Images = ({ campaign }) => {
  if (campaign?.pictures[0]) {
    return (
      <img
        src={campaign?.pictures[0]?.imageUrl}
        aria-hidden
        alt="Image de l’amicale"
      />
    )
  }

  if (campaign?.customer?.photoUrl) {
    return (
      <img
        src={campaign?.customer?.photoUrl}
        aria-hidden
        alt={campaign?.customer?.photo}
      />
    )
  }

  return (
    <div>
      <ImageIcon size={80} />
    </div>
  )
}

Images.propTypes = {
  campaign: shape({
    customer: shape({
      photo: string,
      photoUrl: string,
    }).isRequired,
  }).isRequired,
}

export default Images
