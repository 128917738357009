import { arrayOf, number, shape, string } from 'prop-types'

import Image from '../../../Common/Images'

const Images = ({ campaign, customer }) => {
  if (campaign?.pictures.length > 0) {
    return (
      <Image
        images={
          campaign?.pictures.map((p) => ({
            src: p.imageUrl,
          })) ?? []
        }
      />
    )
  }

  if (customer?.photoUrl) {
    return (
      <Image
        images={[customer.photoUrl].map((url) => ({
          src: url,
        }))}
      />
    )
  }

  return null
}

Images.propTypes = {
  campaign: shape({
    pictures: arrayOf(
      shape({
        id: string.isRequired,
        imageUrl: string.isRequired,
        position: number.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
  customer: shape({
    photo: string,
    photoUrl: string,
  }).isRequired,
}

export default Images
