import { css } from '@emotion/css'

import Facebook from 'react-feather/dist/icons/facebook'
import Linkedin from 'react-feather/dist/icons/linkedin'

import WithMargins from '../Common/WithMargins'

const Footer = () => (
  <div
    className={css`
      background-color: #ba1c18;
    `}
  >
    <WithMargins>
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 60px;
          width: 100%;
          padding: 5px 10px;
          box-sizing: border-box;

          p {
            font-size: 15px;
            margin: 0;
            color: #fff;
          }

          a {
            font-size: 15px;
            color: #fff;
            margin: 0;
            padding: 0;
            text-decoration: none;
          }

          div.links {
            display: flex;
            align-items: center;

            a {
              margin: 0 5px;
              padding: 7px;
              border-radius: 50%;
            }

            a.facebook:hover {
              background-color: #1877f2;
            }

            a.linkedin:hover {
              background-color: #409cca;
            }
          }

          @media (max-width: 450px) {
            & > div.infos {
              h3 {
                font-size: 17px;
              }
              p {
                font-size: 14px;
              }
            }
            & > div.legal {
              p {
                font-size: 11px;
              }

              a {
                font-size: 11px;
              }
            }
          }

          @media (max-width: 360px) {
            & > div.legal {
              p {
                font-size: 10px;
              }

              a {
                font-size: 10px;
              }
            }
          }
        `}
      >
        <p>© Copyright - DMASP</p>
        <div className="links">
          <a href="https://www.dmasp.fr/legal-notice/">Mentions légales</a>
          <a
            target="_blank"
            rel="noreferrer"
            className="facebook"
            href="https://www.facebook.com/DMASP-Sapeurs-Pompiers-385616828582556/"
          >
            <Facebook size={22} />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            className="linkedin"
            href="https://www.linkedin.com/company/dmasp/"
          >
            <Linkedin size={22} />
          </a>
        </div>
      </div>
    </WithMargins>
  </div>
)

export default Footer
