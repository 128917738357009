import { Fragment } from 'react'
import { css, cx } from '@emotion/css'
import { arrayOf, shape, string } from 'prop-types'
import { Link } from 'react-router-dom'
import Chevron from 'react-feather/dist/icons/chevron-right'

const BreadCrumb = ({ paths }) => {
  return (
    <div
      className={cx(
        css`
          display: flex;
          align-items: center;
          margin-bottom: 5px;
          margin: 12px 0;

          & div {
            display: flex;
          }

          & a {
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 5px;
            color: #606060ad;
          }

          & > div:not(:last-child) {
            a {
              font-size: 18px;
              font-weight: 700;
              color: #606060;
            }
          }

          & svg {
            color: #606060;
          }

          & span {
            margin: 0px 8px;
          }
        `,
        'breadcrumb',
      )}
    >
      {paths.map((p, i) => (
        <Fragment key={p.url}>
          <div>
            <Link to={p.url}>{p.label}</Link>
          </div>
          {i !== paths.length - 1 && (
            <span>
              <Chevron size={20} />
            </span>
          )}
        </Fragment>
      ))}
    </div>
  )
}

BreadCrumb.propTypes = {
  paths: arrayOf(
    shape({
      label: string.isRequired,
      url: string.isRequired,
    }).isRequired,
  ).isRequired,
}

export default BreadCrumb
