import { useEffect, useState } from 'react'
import { func, shape, string } from 'prop-types'
import { captureException } from '@sentry/react'
import Djv from 'djv'

export const LOADING = 1
export const LOADED = 0
export const ERROR = -1

const fetchData = async (
  { url, options, schema },
  { setStatus, setResult },
) => {
  setStatus(LOADING)
  try {
    const res = await fetch(url, options)

    if (res.status < 300 && res.status >= 200) {
      const data = await res.json()

      if (schema) {
        const env = new Djv()
        env.addSchema('schema', schema)
        const error = env.validate('schema#', data)

        if (error) {
          console.error(error)
          captureException(new Error(`Invalid schema : ${error}`), {
            contexts: { url, options },
          })
        }
      }

      setResult({ data, status: res.status })
      setStatus(LOADED)
    } else {
      setResult({ status: res.status })
      throw new Error(`bad status code ${res.status}`)
    }
  } catch (error) {
    captureException(error, { contexts: { url, options } })
    console.error(error)
    setStatus(ERROR)
  }
}

const Fetch = ({ children, url, options, schema }) => {
  const [status, setStatus] = useState(LOADING)
  const [result, setResult] = useState(null)

  useEffect(() => {
    fetchData({ url, options, schema }, { setResult, setStatus })
  }, [url, options, setStatus, setResult, schema])

  return children({ status, result, refetch: fetchData })
}

Fetch.defaultProps = {
  options: null,
}

Fetch.propTypes = {
  children: func.isRequired,
  url: string.isRequired,
  options: shape({}),
  schema: shape({}),
}

export default Fetch
