/* eslint-disable max-classes-per-file */
import { addBreadcrumb } from '@sentry/react'
import { CB_CONFIRMATION_ERROR } from '../../../constants/stripe'

export const sendPayment = async (token, data) => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/api/online-sales/visit-payment`
  return fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  })
}

export const sendConfirmationPayment = async (token, body) => {
  const confirmationResult = await sendPayment(token, body)
  if (confirmationResult.status !== 204) {
    addBreadcrumb({
      message: CB_CONFIRMATION_ERROR,
      level: 'error',
      date: { responseContent: await confirmationResult.json() },
    })

    throw new Error('Erreur pendant la procédure de confirmation.')
  }

  return confirmationResult
}

export class ExpiredTokenError extends Error {
  constructor() {
    super(`Expired token`)
  }
}

export class InvalidTokenError extends Error {
  constructor() {
    super(`Expired token`)
  }
}

const errorsByStatus = {
  400: new InvalidTokenError(),
  403: new InvalidTokenError(),
  498: new ExpiredTokenError(),
}

export const fetchVisitStatus = async (token) => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/api/online-sales/visit-status`
  const result = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  const { status } = result
  if (status !== 200) {
    throw (
      errorsByStatus[status] ??
      new Error(`Unhandled result code ${result.status}`)
    )
  }

  return result
}
