import { css, cx } from '@emotion/css'
import { arrayOf, number, shape, string } from 'prop-types'

import P from '../../../UI/P'

import Form from './Form'
import Images from './Images'

const Success = ({ result }) => {
  const haveImages =
    result?.data?.campaign?.pictures?.length > 0 ||
    result?.data?.customer?.photoUrl

  return (
    <div
      className={cx(
        css`
          padding: 5px 10px;

          & h2,
          & h1 {
            margin: 0;
            color: #606060;
          }

          & h1 {
            font-size: 35px;
            font-weight: 500;
          }

          & h2 {
            margin-top: 5px;
            font-size: 27px;
          }

          & .description {
            display: flex;
            margin-top: 17px;

            div {
              margin: auto;
              padding: 10px;
              width: 50%;

              p {
                padding: 10px 0;
                overflow: auto;
                height: auto;
              }
            }
          }

          @media (min-width: 1050px) {
            &.haveImages .description > div > p {
              height: calc(500px - 20px - 32px - 31px - 5px - 40px);
            }
          }

          @media (max-width: 450px) {
            & h2 {
              font-size: 22px;
            }
          }

          @media (max-width: 360px) {
            & h2 {
              font-size: 20px;
            }
          }

          @media (max-width: 1050px) {
            & .images {
              .image_selector {
                display: none;
              }
            }

            & .images.empty {
              display: none;
            }

            & .description {
              flex-direction: column;
              div {
                width: calc(100% - 15px);
              }
            }

            & .description p {
              height: auto;
            }
          }
        `,
        { haveImages },
      )}
    >
      <div className="description">
        <Images
          campaign={result?.data?.campaign}
          customer={result?.data?.customer}
        />
        <div>
          <h1>{result?.data?.customer?.legalName}</h1>
          <h2>{result?.data?.campaign?.name}</h2>
          {result?.data?.campaign?.description ? (
            <P>{result?.data?.campaign?.description}</P>
          ) : (
            <P>Aucune description</P>
          )}
        </div>
      </div>
      <div
        className={css`
          display: flex;
          justify-content: center;
          margin-top: 50px;
          width: 100%;
        `}
      >
        <Form data={result?.data} />
      </div>
    </div>
  )
}

Success.propTypes = {
  result: shape({
    data: shape({
      campaign: shape({
        id: string.isRequired,
        name: string.isRequired,
        description: string,
        minimumPrice: number.isRequired,
        pictures: arrayOf(
          shape({
            id: string.isRequired,
            imageUrl: string.isRequired,
            position: number.isRequired,
          }).isRequired,
        ).isRequired,
      }),
      customer: shape({
        legalName: string.isRequired,
        trigram: string.isRequired,
        sdis: string.isRequired,
        postalCode: number.isRequired,
        city: string.isRequired,
        photo: string,
        photoUrl: string,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

export default Success
