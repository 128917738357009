import { css, cx } from '@emotion/css'
import { arrayOf, bool, func, shape, string } from 'prop-types'
import { useState } from 'react'

const Input = ({ options, name, tabMode, onChange }) => {
  const [selected, setSelected] = useState(options[0]?.id)

  const handleChange = (id) => {
    setSelected(id)
    onChange?.(id)
  }

  return (
    <div
      className={cx(
        css`
          color: #606060;

          &.tabMode {
            display: flex;
            min-height: 50px;
            align-items: center;

            input {
              display: none;
            }

            label {
              padding: 10px 40px;
              cursor: pointer;
              border: 1px solid black;
              transition: background-color 0.2s ease, color 0.2s ease;
            }

            div:first-of-type > label {
              border-top-left-radius: 3px;
              border-bottom-left-radius: 3px;
            }

            div:not(:first-of-type) > label {
              border-left: none;
            }

            div:last-of-type > label {
              border-top-right-radius: 3px;
              border-bottom-right-radius: 3px;
            }

            input:checked + label {
              color: #000;
              background-color: #f4f6f7;
            }
          }
        `,
        { tabMode, radio: true },
      )}
    >
      {options.map((o) => (
        <div key={o.id}>
          <input
            type="radio"
            id={o.id}
            name={name}
            value={o.value}
            onChange={() => handleChange(o.id)}
            checked={selected === o.id}
          />
          <label htmlFor={o.id}>{o.label}</label>
        </div>
      ))}
    </div>
  )
}

Input.defaultProps = {
  tabMode: false,
  onChange: null,
}

Input.propTypes = {
  options: arrayOf(
    shape({
      id: string.isRequired,
      label: string.isRequired,
      value: string.isRequired,
    }).isRequired,
  ).isRequired,
  name: string.isRequired,
  onChange: func,
  tabMode: bool,
}

export default Input
