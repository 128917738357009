/* eslint-disable import/prefer-default-export */

import { addBreadcrumb } from '@sentry/react'
import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'

import {
  GIFT_CONFIRMATION_ERROR,
  GIFT_CREATE_PAYMENT_ERROR,
} from '../../constants/stripe'

export const confirmCardPaymentWithStripe = async (
  stripe,
  clientSecret,
  cardNumberElement,
) => {
  if (!clientSecret) {
    throw new Error('Missing client_secret in API return')
  }

  const { paymentIntent, error } = await stripe.confirmCardPayment(
    clientSecret,
    {
      payment_method: {
        card: cardNumberElement,
      },
    },
  )

  if (paymentIntent?.id && paymentIntent?.status === 'succeeded') {
    return paymentIntent
  }

  addBreadcrumb({
    message: GIFT_CONFIRMATION_ERROR,
    level: 'info',
    data: { paymentIntent, error },
  })
  throw new Error(error ?? 'Unknown error with stripe confirmation')
}

export const createPaymentMethodWithStripe = async (
  stripe,
  cardNumberElement,
) => {
  const { error, paymentMethod } = await stripe.createPaymentMethod({
    type: 'card',
    card: cardNumberElement,
  })

  if (paymentMethod) return paymentMethod

  addBreadcrumb({
    message: GIFT_CREATE_PAYMENT_ERROR,
    level: 'info',
    data: { error },
  })
  throw new Error(error ?? 'Unknown error with stripe create payment method')
}

export const useStripeHelper = () => {
  const elements = useElements()
  const stripe = useStripe()

  const createPaymentMethod = () => {
    const cardNumberElement = elements.getElement(CardNumberElement)
    return createPaymentMethodWithStripe(stripe, cardNumberElement)
  }

  const confirmCardPayment = (clientSecret) => {
    const cardNumberElement = elements.getElement(CardNumberElement)
    return confirmCardPaymentWithStripe(stripe, clientSecret, cardNumberElement)
  }

  return [{ createPaymentMethod, confirmCardPayment }]
}
